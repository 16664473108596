import React, { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';

export function DataTag(props: { children: ReactNode; label: string }) {
  return (
    <Stack>
      <DataLabel>{props.label}</DataLabel>
      <DataField>{props.children}</DataField>
    </Stack>
  );
}

const DataField = styled('span')({
  color: 'rgba(0, 0, 0, 0.87)',
  position: 'relative',
  minWidth: '85px',
  height: 'auto',
  left: '13px',
  alignContent: 'center',
  alignItems: 'auto',
  fontSize: '16px',
  top: '-1px'
});

const DataLabel = styled('span')({
  position: 'relative',
  top: '-9px',
  left: '13px',
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: '400'
});
