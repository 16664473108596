export function redirectToSsoEndpoint() {
  const ssoContext = sessionStorage.getItem('ssoContext');
  if (ssoContext == null) {
    throw new Error('Unable to proceed with SSO! Missing context.');
  }

  // Do not remove context from sessionStorage as
  // user may hit the back button and land on this page again.
  // We want to redirect him to SSO endpoint again.
  // sessionStorage.removeItem('ssoContext');

  window.location.href = `${window.location.origin}/sso?context=${ssoContext}`;
}
