import { sso } from '@app/common-frontend';
import { createApolloClient } from '@everlutionsk/helpers-apollo-react';
import { applyYupLocalePreset } from '@everlutionsk/ui-formik';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './src/app';
import { possibleTypes } from './src/graphql/possibleUnionTypes';
import { redirectToSsoEndpoint } from './src/ssoRedirects';

if (sso.hasLocalSession()) {
  redirectToSsoEndpoint();
} else {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && sso.hasLocalSession()) {
      redirectToSsoEndpoint();
    }
  });

  const root = createRoot(document.getElementById('root')!);

  const apollo = createApolloClient({
    url: '/graphql',
    cacheConfig: { possibleTypes },
    batchInterval: 10
  });

  applyYupLocalePreset('en');

  root.render(<App apollo={apollo} />);
}
