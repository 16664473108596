import { createUiTheme, UiTheme } from '@everlutionsk/ui';
import { createUiAdminTheme, UiAdminTheme } from '@everlutionsk/ui-admin';
import { createUiApolloTheme, UiApolloTheme } from '@everlutionsk/ui-apollo';
import { createUiFormikTheme, UiFormikTheme } from '@everlutionsk/ui-formik';
import { OpenInNew as ExternalLinkIcon } from '@mui/icons-material';
import { alpha, createTheme, Slide } from '@mui/material';
import { amber, indigo, lightBlue, purple, red } from '@mui/material/colors';
import { skSK } from '@mui/material/locale';
import React, { useMemo } from 'react';

export function useTheme({ isProd, dark = false }: { isProd: boolean; dark?: boolean }) {
  const spacing = 8;

  // todo: support Dark Mode
  // const dark = useMediaQuery('(prefers-color-scheme: dark)');

  const palette = {
    primary: 'rgba(145,200,67)',
    secondary: '#7158E2',
    error: '#EF0014',
    gray: '#d6d6d6'
  };

  const defaultTheme = createTheme();

  return useMemo(() => {
    const muiTheme = createTheme(
      {
        palette: {
          mode: dark ? 'dark' : 'light',
          primary: {
            main: palette.primary,
            contrastText: '#fff'
          },
          secondary: {
            main: palette.secondary,
            contrastText: '#fff'
          },
          error: {
            main: palette.error
          },
          background: {
            paper: dark ? '#161B20' : '#FFF',
            default: dark ? '#010409' : '#F3F7F8'
          },
          text: {
            primary: dark ? '#fff' : '#434343',
            secondary: dark ? '#fff' : '#777777',
            disabled: '#B4B3B3'
          },
          accent: {
            light: red.A200,
            main: red.A200,
            dark: red.A200,
            contrastText: '#fff'
          }
        },
        spacing,
        typography: {
          h1: {
            fontSize: '2rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 600,
            marginBottom: spacing * 2,
            color: '#2c4052'
          },
          h2: {
            fontSize: '1.75rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 550,
            marginBottom: spacing * 2,
            color: '#2c4052'
          },
          h3: {
            fontSize: '1.5rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 500,
            marginBottom: spacing * 2,
            color: '#2c4052'
          },
          h4: {
            fontSize: '1.3rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 600,
            marginBottom: spacing * 2,
            color: '#2c4052'
          },
          h5: {
            fontSize: '1.125rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 600,
            color: '#2c4052'
          },
          h6: {
            fontSize: '1rem',
            lineHeight: 1.15,
            letterSpacing: '0.005em',
            fontWeight: 600,
            color: '#2c4052'
          },
          body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 400
          },
          body2: {
            fontSize: '0.875rem',
            lineHeight: 1.5,
            fontWeight: 400
          },
          button: {
            letterSpacing: '0.05em',
            fontWeight: 600
          },
          caption: {
            fontSize: '0.70rem'
          }
        },
        components: {
          MuiAlert: {
            styleOverrides: {
              root: {
                '@media print': {
                  display: 'none'
                }
              },
              standard: {
                border: 'none'
              },
              standardSuccess: {
                outlineColor: alpha(defaultTheme.palette.success.light, 0.18)
              },
              standardError: {
                outlineColor: alpha(defaultTheme.palette.error.light, 0.18)
              },
              standardWarning: {
                outlineColor: alpha(defaultTheme.palette.warning.light, 0.18)
              }
            }
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: 'rgba(145,200,67)',
                '@media print': {
                  border: 'none',
                  '& [aria-label="open drawer"]': { display: 'none' },
                  '& .MuiToolbar-root > .MuiBox-root': {
                    display: 'none'
                  }
                }
              }
            }
          },
          MuiAutocomplete: {
            defaultProps: {
              fullWidth: true
            },
            styleOverrides: {
              root: {
                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { width: 'fit-content' }
              }
            }
          },
          MuiBreadcrumbs: {
            styleOverrides: {
              root: {
                '@media print': {
                  display: 'none'
                }
              }
            }
          },
          MuiButton: {
            defaultProps: {
              variant: 'outlined',
              color: 'secondary',
              size: 'large'
            },
            styleOverrides: {
              contained: {
                backgroundColor: palette.secondary,
                color: '#fff',
                '&:hover': {
                  background: palette.secondary
                },
                borderRadius: '4px'
              }
            }
          },
          MuiChip: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: lightBlue[400]
              },
              colorSuccess: {
                backgroundColor: indigo.A700
              },
              colorInfo: {
                backgroundColor: purple[400]
              },
              colorWarning: {
                backgroundColor: amber[600]
              }
            }
          },
          MuiContainer: {
            defaultProps: {
              maxWidth: 'xl'
            }
          },
          MuiRadio: {
            styleOverrides: {
              root: {
                padding: 6
              }
            }
          },
          MuiTable: {
            styleOverrides: {
              root: {
                border: '1px solid rgba(224, 224, 224, 1)',
                backgroundColor: '#FFFFFF',
                marginTop: -1
              }
            }
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                paddingTop: '10px',
                paddingBottom: '10px',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
              },
              head: {
                backgroundColor: '#ffffff',
                fontWeight: 'bold'
              }
            }
          },
          MuiTablePagination: {
            defaultProps: {
              labelRowsPerPage: 'Počet riadkov na stránku',
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} z ${count !== -1 ? count : `viac ako ${to}`}`
            }
          },
          MuiTabs: {
            defaultProps: {
              scrollButtons: true,
              allowScrollButtonsMobile: true
            },
            styleOverrides: {
              root: {
                borderBottom: '1px solid #e2e2e2'
              }
            }
          },
          MuiPaper: {
            defaultProps: {
              variant: 'outlined'
            }
          },
          MuiTextField: {
            defaultProps: {
              variant: 'outlined',
              size: 'small',
              fullWidth: true
            }
          },
          MuiSnackbar: {
            defaultProps: {
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              TransitionComponent: props => <Slide {...props} direction="down" />
            },
            styleOverrides: {
              root: {
                '.MuiAlert-root': {
                  outlineWidth: '8px',
                  outlineStyle: 'solid',
                  outlineRadius: '4px'
                }
              }
            }
          },
          MuiLink: {
            defaultProps: {
              underline: 'hover',
              fontWeight: 500
            },
            styleOverrides: {
              // Makes sure `<Link component="button"/>` looks the same like `<Link />`
              button: {
                verticalAlign: undefined,
                fontSize: 'inherit'
              },
              root: {
                cursor: 'pointer'
              }
            }
          },
          MuiDialog: {
            defaultProps: {
              maxWidth: 'xs',
              fullWidth: true
            },
            styleOverrides: {
              paper: {
                // Fix scroll issues
                '& > form': {
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }
              }
            }
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: dark ? '#fff' : '#6C7B84'
              }
            }
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                fontSize: '0.9rem'
              }
            }
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                paddingTop: '6px'
              }
            }
          },
          MuiDialogContentText: {
            defaultProps: {
              marginBottom: 1
            }
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                padding: `${spacing * 2}px 20px`
              }
            }
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                [defaultTheme.breakpoints.up('md')]: {
                  minHeight: '84px'
                },
                [defaultTheme.breakpoints.up('lg')]: {
                  minHeight: '84px'
                },
                [defaultTheme.breakpoints.up('xl')]: {
                  minHeight: '84px'
                }
              }
            }
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                maxWidth: '600px'
              }
            }
          }
        }
      },
      skSK
    );

    const uiTheme = createUiTheme(muiTheme, {
      SearchField: {
        defaultProps: {
          fullWidth: true
        }
      },
      Table: {
        defaultProps: {
          paginationPosition: 'both'
        }
      },
      ExternalLink: {
        defaultProps: {
          icon: <ExternalLinkIcon />
        }
      }
    });

    const uiAdminTheme = createUiAdminTheme(muiTheme);

    const uiApolloTheme = createUiApolloTheme(muiTheme, {
      pagination: {
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 50]
      }
    });

    const uiFormikTheme = createUiFormikTheme(muiTheme, {
      Fields: {
        fieldStyle: {
          marginBottom: `${spacing}px`
        }
      }
    });

    const theme = {
      ...muiTheme,
      ...uiTheme,
      ...uiAdminTheme,
      ...uiApolloTheme,
      ...uiFormikTheme
    };

    console.debug('THEME', theme);

    return theme;
  }, [dark, isProd]);
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends UiTheme, UiAdminTheme, UiApolloTheme, UiFormikTheme {}

  interface Palette {
    accent: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    accent: true;
  }
}
