/* eslint-disable */

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT EDIT THIS FILE!
//
// GENERATED BY @everlutionsk/graphql-types-generator package!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Void: void;
  JSONObject: any;
};

export const Role = {
  admin: 'admin',
  psychologist: 'psychologist',
  analyst: 'analyst',
  owner: 'owner'
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type UserRequestCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  position: Array<Scalars['String']>;
  phoneNumber: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
  organisationId: Scalars['String'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type CodeSignInRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CodeSignInRequestMutation = { codeSignInRequest?: Maybe<void> };

export type CodeSignInCompleteMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;

export type CodeSignInCompleteMutation = { codeSignInComplete?: Maybe<void> };

export type UserRequestMutationVariables = Exact<{
  input: UserRequestCreateInput;
}>;

export type UserRequestMutation = { userRequest?: Maybe<void> };

export type RequestUserPositionOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type RequestUserPositionOptionsQuery = {
  positionGroups: Array<{ __typename: 'PositionGroupRadio'; label: string; value: string }>;
};

export type OrganisationAutocompleteCreateUserQueryVariables = Exact<{
  term?: Maybe<Scalars['String']>;
}>;

export type OrganisationAutocompleteCreateUserQuery = {
  organisationAutocomplete: Array<{
    __typename: 'OrganisationAutocompleteValue';
    id: string;
    name: string;
    city?: Maybe<string>;
    line1?: Maybe<string>;
  }>;
};

export type PasswordSignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type PasswordSignInMutation = { passwordSignIn?: Maybe<void> };
