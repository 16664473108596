import { ApolloClient, ApolloProvider } from '@apollo/client';
import { useTheme } from '@app/common-frontend';
import { UiProvider, useLocalStorageState } from '@everlutionsk/ui';
import { Route, Router, Routes, useNavigate } from '@everlutionsk/ui-router';
import React, { useEffect } from 'react';
import { CodeSignIn } from './codeSignIn';
import { environment } from './environment';
// import { PasswordSignIn } from './passwordSignIn';
import { CreateAccount } from './createAccount';

interface AppProps {
  readonly apollo: ApolloClient<any>;
}

export function App(props: AppProps) {
  const theme = useTheme({ isProd: environment.isProd });
  const [signInType, setSignInType] = useLocalStorageState<'sign-in-code' | 'sign-in'>(
    'signInType',
    'sign-in-code'
  );

  return (
    <UiProvider theme={theme} flashMessages={{ timeout: 15_000 }} historyState>
      <ApolloProvider client={props.apollo}>
        <Router>
          <Routes>
            <Route path="/init" element={<Init signInType={signInType} />} />
            {/* <Route path="/sign-in" element={<PasswordSignIn setSignInType={setSignInType} />} /> */}
            <Route path="/sign-in-code" element={<CodeSignIn setSignInType={setSignInType} />} />
            <Route path="/create-account" element={<CreateAccount />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </UiProvider>
  );
}

function Init(props: { signInType: 'sign-in-code' | 'sign-in' }) {
  const navigate = useNavigate();

  useEffect(() => {
    const ssoContext = new URLSearchParams(window.location.search).get('ssoContext');
    if (ssoContext == null) {
      throw new Error('Unable to proceed with SSO! Missing Context.');
    }

    sessionStorage.setItem('ssoContext', ssoContext);

    navigate(`/${props.signInType}`, { replace: true });
  }, []);
  return null;
}
