import { Button, ButtonProps } from '@everlutionsk/ui';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle
} from '@mui/material';
import React, { ReactNode, useState } from 'react';

interface ConfirmDialogProps {
  readonly open: boolean;
  readonly title: ReactNode;

  /**
   * It is recommended to use `<DialogContentText>` to wrap your text.
   */
  readonly content: ReactNode;

  readonly onCancel?: () => void;
  readonly onConfirm: () => Promise<any> | any;
  readonly muiDialogProps?: Omit<MuiDialogProps, 'open' | 'onClose'>;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.onCancel?.()}
      aria-labelledby="dialog-title"
      maxWidth="md"
      fullWidth={false}
      {...props.muiDialogProps}
    >
      <DialogTitle id="dialog-title">{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel?.();
          }}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await props.onConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface ConfirmDialogButtonProps extends Omit<ButtonProps, 'title'> {
  readonly dialog: Omit<ConfirmDialogProps, 'open'>;
}

// todo: move into @everlutionsk/ui
// todo: rename -> ButtonWithDialogConfirm
export function ConfirmDialogButton({ dialog, ...buttonProps }: ConfirmDialogButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={event => {
          setOpen(true);
          buttonProps.onClick?.(event);
        }}
      />
      <ConfirmDialog
        {...dialog}
        open={open}
        onConfirm={async () => {
          await dialog.onConfirm();
          setOpen(false);
        }}
        onCancel={() => {
          dialog.onCancel?.();
          setOpen(false);
        }}
      />
    </>
  );
}
