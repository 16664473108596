import { Box, FormLabel, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

export function FilterWrapper({
  withMargin = false,
  label,
  children
}: {
  withMargin?: boolean;
  label?: string;
  children: ReactNode;
}) {
  return (
    <Stack spacing={1.2}>
      {withMargin && <Box sx={{ mb: 1 }} />}
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        {children}
      </Stack>
    </Stack>
  );
}
