import { Link } from '@everlutionsk/ui-router';
import { Box, styled } from '@mui/material';
import React from 'react';
import { OthersTooltip } from './othersTooltip';

export function CollapsedLinks({
  items,
  ignoredItems,
  path,
  max = 3
}: {
  items: Array<{ id: string; name: string; path?: string }>;
  ignoredItems?: string[];
  path: string;
  max?: number;
}) {
  const rest = items.slice(max);

  return (
    <>
      {items.slice(0, max).map((item, index, all) =>
        ignoredItems?.includes(item.id) ? (
          <LinkWrapper key={item.id}>{item.name}</LinkWrapper>
        ) : (
          <LinkWrapper key={item.id}>
            <Link to={`${item.path ?? path}/${item.id}`}>{item.name}</Link>
          </LinkWrapper>
        )
      )}
      {rest.length > 0 && <OthersTooltip items={rest.map(item => item.name)} />}
    </>
  );
}

const LinkWrapper = styled(Box)`
  background-color: #f3f7f8;
  border: #dfe5e6;
  padding: 3px 8px;
  border-radius: 4px;
  margin-right: 4px;
`;
