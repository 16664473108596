import { Box } from '@mui/material';
import React from 'react';

interface Props {
  readonly value: boolean;
}

// todo: move into UI-Admin package
export function YesNo({ value }: Props) {
  return <Box sx={{ color: value ? 'success.main' : 'warning.main' }}>{value ? 'yes' : 'no'}</Box>;
}
