import { Box, SxProps, Typography } from '@mui/material';
import React, { createContext, ReactNode, useContext } from 'react';

interface CaptionProps {
  readonly children: ReactNode;
  readonly sx?: SxProps;
}

export function Caption(props: CaptionProps) {
  const inCaptionedComponent = useContext(CaptionedContext);

  return (
    <Typography
      variant="caption"
      color="text.secondary"
      lineHeight="inherit"
      sx={{
        mx: inCaptionedComponent ? 0.3 : undefined,
        ...props.sx
      }}
    >
      {props.children}
    </Typography>
  );
}

interface CaptionedProps {
  readonly children: ReactNode;
}

export function Captioned(props: CaptionedProps) {
  return (
    <Box sx={{ display: 'inline' }}>
      <CaptionedContext.Provider value>{props.children}</CaptionedContext.Provider>
    </Box>
  );
}

const CaptionedContext = createContext(false);
CaptionedContext.displayName = 'CaptionedContext';
