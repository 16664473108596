import { Box, styled } from '@mui/material';
import React from 'react';
import logos from './logos.png';
import psychodiagnostika from './psychodiagnostika.png';

export function Footer() {
  return (
    <Wrapper display="flex">
      <Box flexDirection="column" display="flex">
        <img style={{ width: 700, margin: '0 auto 70px auto' }} src={logos} alt="" />
        <Box display="flex" flexDirection="row" justifyContent="center">
          <img width="244px" height="44px" src={psychodiagnostika} alt="psychodiagnostika" />
        </Box>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  @media print {
    display: none;
  }
`;
