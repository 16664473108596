import { Centered, INTERNAL_usePackageTheme } from '@everlutionsk/ui';
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { Properties as CssProperties } from 'csstype';
import React, { ReactNode } from 'react';

export interface AuthCardTheme {
  readonly layerStyle: CssProperties | undefined;
}

interface AuthCardProps {
  readonly children: ReactNode;
  readonly title: ReactNode;
  readonly description: ReactNode;
  readonly logo: string;
  readonly companyName: string;
}

export function AuthCard(props: AuthCardProps) {
  const { layerStyle } = INTERNAL_usePackageTheme('@everlutionsk/ui-admin').AuthCard;
  return (
    <Centered>
      <Box>
        <Box mb={1} sx={{ p: 1, borderRadius: '5px', ...layerStyle }}>
          <Card sx={{ borderRadius: '5px' }}>
            <CardContent sx={{ p: 4, pt: 5, maxWidth: '830px' }}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <img src={props.logo} width={150} height="auto" />
                </Grid>

                <Grid item xs={12} sm={9}>
                  {props.title && (
                    <Typography sx={{ mb: 0 }} variant="h1">
                      {props.title}
                    </Typography>
                  )}

                  {props.description && (
                    <Typography color="text.secondary" variant="body2" sx={{ mb: 2, mt: 1 }}>
                      {props.description}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={0} sm={3} />
                <Grid item xs={12} sm={9}>
                  {props.children}
                  <Stack direction="row" justifyContent="center" pt={3}>
                    <Typography variant="body2">
                      V prípade nejasností nás kontaktujte emailom na:{' '}
                      <a href="mailto:eporadenstvo@vudpap.sk" style={{ color: 'inherit' }}>
                        eporadenstvo@vudpap.sk
                      </a>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Typography color="text.disabled" variant="caption">
            &copy; {new Date().getFullYear()} {props.companyName}.
          </Typography>
        </Box>
      </Box>
    </Centered>
  );
}
