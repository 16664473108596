import { styled, Tooltip } from '@mui/material';
import React from 'react';

export function OthersTooltip({ items }: { items: string[] }) {
  return (
    <Tooltip title={items.join(', ')} placement="right">
      <StyledSpan>
        &nbsp; (+ {items.length} {items.length === 1 ? 'other' : 'others'})
      </StyledSpan>
    </Tooltip>
  );
}

const StyledSpan = styled('span')`
  white-space: nowrap;
  user-select: none;
  cursor: help;
  color: ${({ theme }) => theme.palette.text.disabled};
`;
