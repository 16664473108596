import { Link } from '@mui/material';
import React, { ReactNode } from 'react';

type URL = string;

interface DownloadLinkProps {
  readonly filename: string;
  readonly onDownload: () => Promise<URL> | URL;
  readonly children: ReactNode;
}

export function DownloadLink(props: DownloadLinkProps) {
  return (
    <Link
      component="button"
      onClick={async () => {
        const url = await props.onDownload();
        const element = document.createElement('a');
        element.href = url;
        element.target = '_parent';
        element.download = props.filename;
        element.click();
      }}
    >
      {props.children}
    </Link>
  );
}
