import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import { Button } from '@everlutionsk/ui';
import { SaveAlt } from '@mui/icons-material';
import React from 'react';

export interface FilteredExportProps<T> {
  readonly filter: T;
}

export interface ExportButtonProps<T extends TypedDocumentNode<any, any>> {
  readonly query: T;
  readonly variables: T['__variablesType'];
  readonly extract: (result: T['__resultType']) => string;
  readonly disabled?: boolean;
  readonly fileName?: string;
}

export function ExportButton<T extends TypedDocumentNode<any, any>>({
  query,
  variables,
  extract,
  disabled,
  fileName = 'Export'
}: ExportButtonProps<T>) {
  const apollo = useApolloClient();

  return (
    <Button
      startIcon={<SaveAlt />}
      variant="text"
      color="primary"
      size="small"
      sx={{ textTransform: 'none', display: 'flex', textAlign: 'left' }}
      onClick={async () => {
        const { data } = await apollo.query({ query, variables });
        if (data == null) throw Error('No data returned!');

        const element = document.createElement('a');
        element.href = extract(data);
        element.target = '_blank';
        element.click();
      }}
      disabled={disabled}
    >
      {fileName}
    </Button>
  );
}
