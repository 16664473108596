import { gql } from '@apollo/client';
import { delay } from '@everlutionsk/helpers';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Fields, SubmitButton, TextField, createFormSpec } from '@everlutionsk/ui-formik';
import { Link } from '@everlutionsk/ui-router';
import { ArrowForward as SignInIcon } from '@mui/icons-material';
import { Box, Link as MuiLink, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { AuthCard } from './authCard';
import logo from './logo.png';
import { redirectToSsoEndpoint } from './ssoRedirects';
import { Footer } from './footer';

export function CodeSignIn(props: { setSignInType: (value: 'sign-in-code' | 'sign-in') => void }) {
  const [requestedFor, setRequestedFor] = useState<string | undefined>();

  useEffect(() => {
    props.setSignInType('sign-in-code');
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgb(247, 247, 247)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '360px',
          flexShrink: 0,
          flexGrow: 1
        }}
      >
        <AuthCard
          logo={logo}
          title="Prihlásiť sa pomocou e-mailového kódu"
          description="Pre používanie platformy Eporadenstvo sa prihláste."
          companyName="Eporadenstvo"
        >
          {requestedFor == null ? (
            <RequestCode onSubmit={setRequestedFor} />
          ) : (
            <ConfirmCode email={requestedFor} reset={() => setRequestedFor(undefined)} />
          )}
        </AuthCard>
      </Box>
      <Footer />
    </Box>
  );
}

function RequestCode({ onSubmit }: { onSubmit: (email: string) => void }) {
  const [requestCode] = useFlashMutation(requestCodeMutation);

  const formSpec = useMemo(
    () => createFormSpec({ email: yup.string().required('Pole email je povinné') }),
    []
  );

  return (
    <Formik
      {...formSpec({ email: '' })}
      onSubmit={async values => {
        await requestCode({ variables: { email: values.email } });
        onSubmit(values.email);
      }}
    >
      <Form>
        <Fields>
          <TextField
            style={{ marginBottom: '16px' }}
            size="small"
            autoFocus
            name="email"
            type="text"
            label="Email"
            autoComplete="username"
            placeholder="Zadajte emailovú adresu"
          />

          <SubmitButton size="large" endIcon={<SignInIcon />} fullWidth>
            Odoslať kód
          </SubmitButton>

          <Link to={'/create-account'}>
            <Stack direction="row" justifyContent="center" mt={2}>
              Vytvoriť konto
            </Stack>
          </Link>
        </Fields>
      </Form>
    </Formik>
  );
}

function ConfirmCode({ email, reset }: { email: string; reset: () => void }) {
  const [confirmCode] = useFlashMutation(confirmCodeMutation);

  const formSpec = useMemo(
    () =>
      createFormSpec({
        email: yup.string().email().required('Pole e-mail je povinné.'),
        code: yup.string().required('Pole kód je povinné.')
      }),
    []
  );

  return (
    <Formik
      {...formSpec({ email, code: '' })}
      onSubmit={async values => {
        await confirmCode({ variables: { email: values.email, code: values.code } });

        redirectToSsoEndpoint();

        // Postpone promise resolution to prevent turning submit button back to "ready" state.
        await delay(10000);
      }}
    >
      <Form>
        <Fields>
          <TextField
            style={{ marginBottom: '16px' }}
            size="small"
            name="email"
            type="text"
            label="Email"
            autoComplete="username"
            placeholder="Zadajte emailovú adresu"
            disabled
          />

          <TextField
            size="small"
            autoFocus
            name="code"
            type="text"
            label="Kód"
            autoComplete="one-time-code"
            inputMode="numeric"
            inputProps={{ pattern: '[0-9]*' }}
            placeholder="Zadajte kód, ktorý sme vám zaslali emailom"
          />

          <Typography variant="caption" sx={{ marginBottom: '16px' }}>
            Nesprávna emailová adresa? <MuiLink onClick={reset}>Použiť inú.</MuiLink>
          </Typography>

          <SubmitButton size="large" endIcon={<SignInIcon />} fullWidth>
            Potvrdiť kód a prihlásiť sa
          </SubmitButton>
        </Fields>
      </Form>
    </Formik>
  );
}

const requestCodeMutation = gql<CodeSignInRequestGQL>`
  mutation CodeSignInRequest($email: String!) {
    codeSignInRequest(email: $email)
  }
`;

const confirmCodeMutation = gql<CodeSignInCompleteGQL>`
  mutation CodeSignInComplete($email: String!, $code: String!) {
    codeSignInComplete(email: $email, code: $code)
  }
`;
