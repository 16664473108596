import { Centered } from '@everlutionsk/ui';
import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import backgroundImage from './bg-image.webp';

export function AuthScreen({ children }: { children: ReactNode }) {
  return (
    <Background>
      <Centered sx={{ top: '-25%', left: '-2%' }}>
        <Box display={{ md: 'unset', sm: 'none', xs: 'none' }}>
          <img src={backgroundImage} style={{ width: '87%' }} />
        </Box>
      </Centered>
      {children}
    </Background>
  );
}

const Background = styled(Box)`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #041e2d;
`;
