import { gql, useQuery } from '@apollo/client';
import { Button } from '@everlutionsk/ui';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  AutocompleteField,
  CheckboxField,
  Fields,
  MultiAutocompleteField,
  SubmitButton,
  TextField,
  createFormSpec
} from '@everlutionsk/ui-formik';
import { Box, InputAdornment, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { AuthCard } from './authCard';
import logo from './logo.png';
import { Role } from './graphql/types';
import { useNavigate } from '@everlutionsk/ui-router';

export function CreateAccount() {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        backgroundColor: 'rgb(247, 247, 247)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '900px',
          flexShrink: 0,
          flexGrow: 1
        }}
      >
        <AuthCard
          logo={logo}
          title="Vytvoriť konto"
          description="Pre používanie platformy Eporadenstvo sa prihláste."
          companyName="Eporadenstvo"
        >
          <CreateAccountForm />
        </AuthCard>
      </Box>
    </Box>
  );
}

function CreateAccountForm() {
  const navigate = useNavigate();
  const [term, setTerm] = useState('');

  const { data: groupOptions } = useQuery(optionQuery);
  const { data: organisationOptions } = useQuery(organisationQuery, { variables: { term } });

  const [request] = useFlashMutation(mutation, {
    errorMsg: 'Konto sa nepodarilo vytvoriť',
    successMsg:
      'Pre úspešné vytvorenie konta je potrebné schválenie administrátora. O spracovaní Vašej žiadosti Vás budeme informovať e-mailom.'
  });

  const formSpec = useMemo(() => {
    return createFormSpec({
      firstName: yup.string().nullable().required(`Meno je povinný údaj`),
      lastName: yup.string().nullable().required(`Priezvisko je povinný údaj`),
      title: yup.string().nullable(),
      position: yup.array().nullable().required(`Pozícia zamestnanca je povinný údaj`),
      phoneNumber: yup
        .string()
        .nullable()
        .min(9, 'Telefónne číslo musí obsahovať 9 číslic')
        .max(9, 'Telefónne číslo musí obsahovať 9 číslic'),
      email: yup
        .string()
        .nullable()
        .required(`Email zamestnanca je povinný údaj`)
        .email('Formát emailu je nesprávny'),
      role: yup.mixed().required('Rola je povinný údaj'),
      organisation: yup.mixed().required('Organizácia je povinný údaj')
    });
  }, []);

  return (
    <Formik
      {...formSpec({
        firstName: '',
        lastName: '',
        title: '',
        position: [],
        phoneNumber: '',
        email: '',
        role: false,
        organisation: null
      })}
      onSubmit={async ({
        email,
        firstName,
        lastName,
        phoneNumber,
        position,
        role,
        title,
        organisation
      }) => {
        await request({
          variables: {
            input: {
              firstName,
              lastName,
              email,
              phoneNumber: `+421${phoneNumber}`,
              position: position.map(item => item.value),
              role: role ? Role.owner : Role.psychologist,
              title,
              organisationId: organisation.value
            }
          }
        });
        navigate('/sign-in-code');
      }}
    >
      <Form>
        <Fields>
          <TextField name="firstName" label="Meno*" />
          <TextField name="lastName" label="Priezvisko*" />
          <TextField name="title" label="Titul*" />
          <MultiAutocompleteField
            label="Pozícia*"
            name="position"
            options={groupOptions?.positionGroups}
          />
        </Fields>
        <Box mb={2}>
          <AutocompleteField
            label="Organizácia*"
            name="organisation"
            options={organisationOptions?.organisationAutocomplete.map(item => ({
              label: `${item.name} ${item.city} ${item.line1}`,
              value: item.id,
              node: (
                <Box display="flex" flexDirection="row" alignItems="center" key={item.id}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">
                      <b>{item.name}</b>
                    </Typography>
                    {item.city && item.line1 && (
                      <Typography variant="body2">
                        ({item.city} {item.line1})
                      </Typography>
                    )}
                  </Box>
                </Box>
              )
            }))}
            onTermChange={setTerm}
          />
          <CheckboxField name="role" label="Riadim organizáciu a nárokujem si jej správu" />
        </Box>
        <Fields>
          <TextField
            name="phoneNumber"
            label="Telefón*"
            inputProps={{ maxLength: 9 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">+421</InputAdornment>
            }}
          />
          <TextField name="email" label="Email*" />
        </Fields>
        {/* TODO: styling and when youre adding positions to autocomplete its making the form screen wider*/}
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <SubmitButton>Vytvoriť</SubmitButton>
          <Box my={1}>
            <Typography variant="caption">alebo</Typography>
          </Box>
          <Button color="secondary" onClick={() => navigate(-1)}>
            Späť
          </Button>
        </Box>
      </Form>
    </Formik>
  );
}

const mutation = gql<UserRequestGQL>`
  mutation UserRequest($input: UserRequestCreateInput!) {
    userRequest(input: $input)
  }
`;

const optionQuery = gql<RequestUserPositionOptionsGQL>`
  query RequestUserPositionOptions {
    positionGroups {
      label
      value
    }
  }
`;

const organisationQuery = gql<OrganisationAutocompleteCreateUserGQL>`
  query OrganisationAutocompleteCreateUser($term: String) {
    organisationAutocomplete(page: { limit: 100, offset: 0 }, term: $term) {
      id
      name
      city
      line1
    }
  }
`;
