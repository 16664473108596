import React from 'react';
import { Link } from '@everlutionsk/ui-router';
import { Box, styled } from '@mui/material';
import eporadentsvoLogo from './eporadenstvo-logo.png';
import vudpapLogo from './vudpap-logo.png';

export function PageLogo() {
  const alt = 'ePoradenstvo';

  return (
    <Box bgcolor="white">
      <Box>
        <Link to={'/'}>
          <StyledImg src={eporadentsvoLogo} alt={alt} />
        </Link>
      </Box>
      <Box textAlign={'center'}>
        <a href="https://www.vudpap.sk" target="_blank" rel="noopener noreferrer">
          <StyledImg src={vudpapLogo} alt="https://www.vudpap.sk" />
        </a>
      </Box>
    </Box>
  );
}

const StyledImg = styled('img')`
  && {
    max-width: 200px;
  }
`;
